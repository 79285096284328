/** @format */

import React from 'react';
import ProjectBar from '../components/ProjectBar';

import Layout from '../components/layout';
import SmallPhoto from '../components/SmallPhoto';
import Photo from '../components/Photo';
// import Text from "../components/Text"
import Title from '../components/Title';
// import Test from '../components/test.md';
import mb2 from '../images/mbcover.png';
import mb3 from '../images/mb3.png';
import mb4 from '../images/mb1.png';
import mb5 from '../images/mb2.png';
import mb6 from '../images/mb4.png';
import mb7 from '../images/mb5.png';
import mb8 from '../images/mb32.png';

import { navigate } from 'gatsby-link';

import lu from '../images/lucover2.png';
import mb from '../images/mbcoverlarge.png';
import penskecover from '../images/penskecoverlarge.png';
import otherwork from '../images/otherwork.jpg';

const MB = () => {
    return (
        <Layout>
            {/* <ProjectBar /> */}

            <Title
                title="Mallard Bay Outdoors"
                subtitle="AirBnb for Hunting and Fishing Experiences"
                categories="UX/UI Design, Development"
            />
            <Photo link={mb2} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <br></br>
                        <p className="firstp">
                            Mallard Bay approached me to redesign a mockup
                            purchased on Fiverr. They felt the original design
                            they received did not match their brand and felt
                            outdated and clunky.
                        </p>
                    </div>
                </div>
            </div>
            <p>
                <SmallPhoto link={mb3} subtitle="Original Design from Fiverr" />
            </p>
            <p>
                <SmallPhoto link={mb6} subtitle="Design Update Sketches" />
            </p>
            <p>
                <SmallPhoto link={mb7} subtitle="Design Update Sketches 2" />
            </p>
            <p>
                <SmallPhoto link={mb4} subtitle="Updated Design" />
            </p>
            <p>
                <SmallPhoto link={mb8} subtitle="Experience Page" />
            </p>
            <p>
                <SmallPhoto
                    link={mb5}
                    subtitle="Dual Home Screen Design. The user taps the icon in the top right to switch between hunting and fishing experiences."
                />
            </p>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3></h3>
                        <p></p>
                    </div>
                </div>
            </div>
            {/* <ProjectBar /> */}

            <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container"
                            onClick={() => navigate(`/penske/`)}>
                            <img
                                className="project-photo"
                                src={penskecover}></img>
                            <div className="project-name">Penske</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>

                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/mallardbay/`)}>
                            <img className="project-photo" src={mb}></img>
                            <div className="project-name">Mallard Bay</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img
                                className="project-photo"
                                src={otherwork}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default MB;
